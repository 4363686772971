// src/components/Navbar.js
import React from 'react';
import styled from 'styled-components';

const SocialsWrapper = styled.p`
  font-family: Water, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  padding: 10px;
  margin: 1em 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  display: flex;
  flex-direction: row;
`;

const SocialLink = styled.a`
font-family: Water, sans-serif;
letter-spacing: 2px;
text-align: center;
color: #fff;
text-decoration: none;
padding: 0 20px;

svg {
  width: 25px;
}
`;

const SocialImage = styled.img`
  
`;

const Navbar = () => {
  return (
    <SocialsWrapper>
      <SocialLink href="https://www.dextools.io/app/en/solana/pair-explorer/BSzedbEvWRqVksaF558epPWCM16avEpyhm2HgSq9WZyy?t=1711734019696" target="_blank" rel="noopener noreferrer">
        <SocialImage width="25" src="/static/dextools.webp" />
      </SocialLink>
      <SocialLink href="https://twitter.com/SharkCatSolana" target="_blank" rel="noopener noreferrer">
        <SocialImage width="25" src="/static/twitter.webp" />
      </SocialLink>
      <SocialLink href="https://t.me/sharkcatonsolana" target="_blank" rel="noopener noreferrer">
        <SocialImage width="25" src="/static/telegram.webp" />
      </SocialLink>
      <SocialLink href="/static/paper.pdf" target="_blank" rel="noopener noreferrer">
        <SocialImage width="25" src="/static/paper.png" />
      </SocialLink>
      <SocialLink href="https://linktr.ee/SHARKCATSOLANA" target="_blank" rel="noopener noreferrer">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="m13.511 5.853 4.005-4.117 2.325 2.381-4.201 4.005h5.909v3.305h-5.937l4.229 4.108-2.325 2.334-5.741-5.769-5.741 5.769-2.325-2.325 4.229-4.108H2V8.122h5.909L3.708 4.117l2.325-2.381 4.005 4.117V0h3.473v5.853zM10.038 16.16h3.473v7.842h-3.473V16.16z"></path></svg>
      </SocialLink>
    </SocialsWrapper>
  );
};

export default Navbar;
