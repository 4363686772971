// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import styles from "./index.module.scss";

const Segue = () => {

  const [innerText, setInnerText] = useState('6D7NaB2xsLd7cauWu1wKk6KBsJohJmP2qZH9GEfVi5Ui');
  const [timer, setTimer] = useState(null);

  const copyToClipboard = () => {
    // Copy text to clipboard
    const textToCopy = '6D7NaB2xsLd7cauWu1wKk6KBsJohJmP2qZH9GEfVi5Ui';
    navigator.clipboard.writeText(textToCopy);

    // Change inner text to "Copied!"
    setInnerText('Copied!');

    // Clear any existing timers before setting a new one
    if (timer) {
      clearTimeout(timer);
    }

    // Reset inner text back to the original after 500ms
    const newTimer = setTimeout(() => {
      setInnerText('6D7NaB2xsLd7cauWu1wKk6KBsJohJmP2qZH9GEfVi5Ui');
    }, 1000);
    setTimer(newTimer);
  };

  // Clean up the timer when the component is unmounted
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  
  return (
    <>
        <div className={styles.address}>
            <p className={styles.contractText}>Contract Address</p>
            <span onClick={copyToClipboard}>{innerText}</span>
        </div>
        <h2 className={styles.font}>COMMUNITY OWNED</h2>
        <h2 className={styles.font}>DEV GOT EATEN BY A SHARK</h2>
        <p className={styles.community}>
            <img width="600" src="/static/nala2.jpeg" />
        </p>
        <h2 className={styles.font}>it swims and it bites, don’t overthink it</h2>
    </>
  );
};

export default Segue;
